/* src/App.css */

.content {
  display: flex;
  position: relative;
  height: calc(100vh - 20px); /* Adjust according to header height */
  width: 100%;
  background-color: #f6f6ef;
}

.story-list {
  padding: var(--story-list-padding, 20px);
  overflow-y: auto;
  overflow-x: auto;
  text-overflow: ellipsis;
  background-color: #f6f6ef;
  flex-shrink: 0;
  transition: width 0.3s ease, padding 0.3s ease;
  height: 100%;
}

.divider {
  width: 5px;
  background-color: #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: ew-resize;
  z-index: 1;
}


.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f6f6ef;
}


.app.story-selected .story-list {
  transition: width 0.3s ease;
}

.app.story-selected .story-detail {
  transition: width 0.3s ease;
}

.button-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 10;
}

.action-buttons {
  display: flex;
  gap: 10px;
  width: calc(100% - 50px); /* Adjust width to account for close button */
  justify-content: center; /* Center the buttons */
  flex-wrap: wrap; /* Allow buttons to wrap if needed */
}

.read-story-button,
.read-comments-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  flex: 1 1 45%; /* Allow buttons to grow and shrink, set basis to 45% */
  max-width: 200px; /* Set a maximum width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.read-story-button:hover {
  background-color: #ab03ff;
}

.read-comments-button:hover {
  background-color: #0056b3;
}

.close-button {
  background-color: #ff4d4d;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute; /* Change from relative to absolute */
  right: 10px; /* Adjust the right position to ensure it stays within the container */
  top: 10px; /* Add top position to ensure it stays within the container */
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #ff1a1a;
}

.close-button:hover .slide-text {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.slide-text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateX(100%) translateY(-50%);
  background-color: #555;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  transition: opacity 0.3s, transform 0.3s;
}

.story-detail {
  position: relative; /* Ensure absolute positioning is relative to this div */
  padding: 20px;
  background-color: #fff;
  overflow: auto; /* Add scrollbars when content overflows */
  flex-shrink: 0; /* Allow it to grow and shrink */
  transition: width 0.3s ease;
  height: 100%;
}

.section-header {
  background-color: rgba(255, 102, 0, 0.3); /* Light gray background */
  padding-left: 10px;
  border-radius: 0px;
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0px;
}

.loading-container {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: left;
  flex-direction: row;
  gap: 10px;
  color: #ff6600;
  font-size: 16px;
}
.share-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  position: relative;
  color: #007bff;
  transition: all 0.2s ease;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.share-button:hover {
  background: rgba(0, 123, 255, 0.1);
  transform: scale(1.1);
}

.share-button .material-symbols-outlined {
  font-size: 24px;
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.copy-tooltip {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  animation: fadeInOut 2s forwards;
  margin-left: 8px; /* Add some spacing from the button */
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateX(-10px) translateY(-50%); }
  10% { opacity: 1; transform: translateX(0) translateY(-50%); }
  90% { opacity: 1; transform: translateX(0) translateY(-50%); }
  100% { opacity: 0; transform: translateX(-10px) translateY(-50%); }
}

.share-link {
  position: absolute;
  left: 120%;
  background: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.share-button:hover .share-link {
  opacity: 1;
}